import React, { useRef } from 'react'
import { motion, useScroll, useTransform } from 'framer-motion'

import { BracketItem } from '../types'

const BracketRight: React.FunctionComponent<BracketItem> = ({ colors }) => {
  const targetRef = useRef<HTMLDivElement>(null)

  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ['start 450px', 'start end'],
  })

  const item1x = useTransform(scrollYProgress, [0, 1], [0, 50])
  const item1y = useTransform(scrollYProgress, [0, 1], [0, -50])

  const item2x = useTransform(scrollYProgress, [0, 1], [0, 125])
  const item2y = useTransform(scrollYProgress, [0, 1], [0, -125])

  const item3x = useTransform(scrollYProgress, [0, 1], [0, 150])
  const item3y = useTransform(scrollYProgress, [0, 1], [0, 150])

  const item4x = useTransform(scrollYProgress, [0, 1], [0, 175])
  const item4y = useTransform(scrollYProgress, [0, 1], [0, -175])

  const item5x = useTransform(scrollYProgress, [0, 1], [0, 250])
  const item5y = useTransform(scrollYProgress, [0, 1], [0, 250])

  const item6x = useTransform(scrollYProgress, [0, 1], [0, 350])
  const item6y = useTransform(scrollYProgress, [0, 1], [0, -300])

  const item7x = useTransform(scrollYProgress, [0, 1], [0, 300])
  const item7y = useTransform(scrollYProgress, [0, 1], [0, 300])

  return (
    <div className="animation-spreat__right" ref={targetRef}>
      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        width="277"
        height="327"
        viewBox="0 0 277 72"
        fill="none"
      >
        <motion.g style={{ x: item1x, y: item1y }}>
          <motion.path
            d="M25.3371 71.8112V47.897H49.6025V71.8112H25.3371Z"
            fill={
              colors && colors[Math.floor(Math.random() * colors.length)]?.color
            }
          />
          <motion.path
            d="M25.3337 47.8971V23.9829C38.7359 23.9829 49.6025 34.6908 49.6025 47.8971H25.3337Z"
            fill={
              colors && colors[Math.floor(Math.random() * colors.length)]?.color
            }
          />
          <motion.path
            d="M1.0681 71.8112V47.897H25.3369V71.8112H1.0681Z"
            fill={
              colors && colors[Math.floor(Math.random() * colors.length)]?.color
            }
          />
          <motion.path
            d="M25.1741 11.9915C25.1741 5.36702 30.624 0 37.3434 0C44.0661 0 49.5127 5.36702 49.5127 11.9915C49.5127 18.6159 44.0661 23.983 37.3434 23.983C30.624 23.983 25.1741 18.6159 25.1741 11.9915Z"
            fill={
              colors && colors[Math.floor(Math.random() * colors.length)]?.color
            }
          />
          <motion.path
            d="M0.835245 35.9383C0.835245 29.3171 6.28185 23.9468 13.0045 23.9468C19.7239 23.9468 25.1738 29.3138 25.1738 35.9383C25.1738 42.5627 19.7272 47.9297 13.0045 47.9297C6.28185 47.9297 0.835245 42.5627 0.835245 35.9383Z"
            fill={
              colors && colors[Math.floor(Math.random() * colors.length)]?.color
            }
          />
        </motion.g>

        <motion.g style={{ x: item2x, y: item2y }}>
          <motion.path
            d="M105.449 23.9043V71.7294H56.9145C56.9145 45.3167 78.6444 23.9043 105.449 23.9043Z"
            fill={
              colors && colors[Math.floor(Math.random() * colors.length)]?.color
            }
          />
          <motion.path
            d="M105.449 71.7294H69.6786C72.377 53.549 87.0021 39.1376 105.449 36.4819V71.7294Z"
            fill={
              colors && colors[Math.floor(Math.random() * colors.length)]?.color
            }
          />
        </motion.g>

        <motion.g style={{ x: item3x, y: item3y }}>
          <motion.path
            d="M137.027 47.8185V23.9043H161.292V47.8185H137.027Z"
            fill={
              colors && colors[Math.floor(Math.random() * colors.length)]?.color
            }
          />
          <motion.path
            d="M137.027 71.7293V47.8184H161.292V71.7293H137.027Z"
            fill={
              colors && colors[Math.floor(Math.random() * colors.length)]?.color
            }
          />
          <motion.path
            d="M137.027 47.8185H112.758C112.758 34.6121 123.625 23.9043 137.027 23.9043V47.8185Z"
            fill={
              colors && colors[Math.floor(Math.random() * colors.length)]?.color
            }
          />
        </motion.g>

        <motion.g style={{ x: item4x, y: item4y }}>
          <motion.path
            d="M179.93 37.497L198.054 37.497L198.054 19.3735C188.052 19.3714 179.93 27.4934 179.93 37.497Z"
            fill={
              colors && colors[Math.floor(Math.random() * colors.length)]?.color
            }
          />
          <motion.path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M170.043 59.2271C178.601 58.4534 185.311 51.2591 185.311 42.4966H198.054C198.054 58.3001 185.648 71.2034 170.043 72.0003V59.2271Z"
            fill={
              colors && colors[Math.floor(Math.random() * colors.length)]?.color
            }
          />
        </motion.g>

        <motion.g style={{ x: item5x, y: item5y }}>
          <motion.path
            d="M228.051 42.4966H203.053C203.053 56.2946 214.256 67.4944 228.051 67.4944V42.4966Z"
            fill={
              colors && colors[Math.floor(Math.random() * colors.length)]?.color
            }
          />
        </motion.g>

        <motion.g style={{ x: item6x, y: item6y }}>
          <motion.path
            d="M233.051 67.4944H252.424V48.1211C241.723 48.1211 233.051 56.794 233.051 67.4944Z"
            fill={
              colors && colors[Math.floor(Math.random() * colors.length)]?.color
            }
          />
          <motion.path
            d="M240.256 67.4943H252.424V55.3267C245.704 55.3267 240.256 60.7745 240.256 67.4943Z"
            fill={
              colors && colors[Math.floor(Math.random() * colors.length)]?.color
            }
          />
        </motion.g>

        <motion.g style={{ x: item7x, y: item7y }}>
          <motion.path
            d="M257.423 58.1203C257.423 52.9431 261.62 48.7461 266.798 48.7461C271.975 48.7461 276.172 52.9431 276.172 58.1203C276.172 63.2975 271.975 67.4945 266.798 67.4945C261.62 67.4945 257.423 63.2975 257.423 58.1203Z"
            fill={
              colors && colors[Math.floor(Math.random() * colors.length)]?.color
            }
          />
          <motion.path
            d="M266.798 62.3057C269.109 62.3057 270.983 60.4313 270.983 58.1204C270.983 55.8094 269.109 53.9351 266.798 53.9351C264.487 53.9351 262.612 55.8094 262.612 58.1204C262.612 60.4313 264.487 62.3057 266.798 62.3057Z"
            fill={
              colors && colors[Math.floor(Math.random() * colors.length)]?.color
            }
          />
        </motion.g>
      </motion.svg>
    </div>
  )
}

export default BracketRight
