import React, { useRef } from 'react'
import { motion } from 'framer-motion'

import { useScroll, useTransform } from 'framer-motion'

import { BracketItem } from '../types'

const BracketLeft: React.FunctionComponent<BracketItem> = ({ colors }) => {
  const targetRef = useRef<HTMLDivElement>(null)

  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ['start 450px', 'start end'],
  })

  const item1x = useTransform(scrollYProgress, [0, 1], [0, -50])
  const item1y = useTransform(scrollYProgress, [0, 1], [0, -50])

  const item2x = useTransform(scrollYProgress, [0, 1], [0, -125])
  const item2y = useTransform(scrollYProgress, [0, 1], [0, -125])

  const item3x = useTransform(scrollYProgress, [0, 1], [0, -150])
  const item3y = useTransform(scrollYProgress, [0, 1], [0, 150])

  const item4x = useTransform(scrollYProgress, [0, 1], [0, -175])
  const item4y = useTransform(scrollYProgress, [0, 1], [0, -175])

  const item5x = useTransform(scrollYProgress, [0, 1], [0, -250])
  const item5y = useTransform(scrollYProgress, [0, 1], [0, 250])

  const item6x = useTransform(scrollYProgress, [0, 1], [0, -350])
  const item6y = useTransform(scrollYProgress, [0, 1], [0, -300])

  const item7x = useTransform(scrollYProgress, [0, 1], [0, -300])
  const item7y = useTransform(scrollYProgress, [0, 1], [0, 300])

  return (
    <div className="animation-spreat__left" ref={targetRef}>
      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        width="277"
        height="327"
        viewBox="0 0 441 116"
        fill="none"
      >
        <motion.g style={{ x: item1x, y: item1y }}>
          <motion.path
            d="M401.369 115.303V77.0369H362.541V115.303H401.369Z"
            fill={
              colors && colors[Math.floor(Math.random() * colors.length)]?.color
            }
          />
          <motion.path
            d="M401.374 77.037V38.771C379.929 38.771 362.541 55.905 362.541 77.037H401.374Z"
            fill={
              colors && colors[Math.floor(Math.random() * colors.length)]?.color
            }
          />
          <motion.path
            d="M440.202 115.303V77.0369H401.369V115.303H440.202Z"
            fill={
              colors && colors[Math.floor(Math.random() * colors.length)]?.color
            }
          />
          <motion.path
            d="M401.63 19.583C401.63 8.98299 392.909 0.39502 382.157 0.39502C371.4 0.39502 362.685 8.98299 362.685 19.583C362.685 30.1831 371.4 38.7711 382.157 38.7711C392.909 38.7711 401.63 30.1831 401.63 19.583Z"
            fill={
              colors && colors[Math.floor(Math.random() * colors.length)]?.color
            }
          />
          <motion.path
            d="M440.575 57.9014C440.575 47.3066 431.86 38.7134 421.102 38.7134C410.351 38.7134 401.63 47.3014 401.63 57.9014C401.63 68.5014 410.345 77.0894 421.102 77.0894C431.86 77.0894 440.575 68.5014 440.575 57.9014Z"
            fill={
              colors && colors[Math.floor(Math.random() * colors.length)]?.color
            }
          />
          <motion.path
            d="M440.575 19.583C440.575 8.98299 431.86 0.39502 421.102 0.39502C410.351 0.39502 401.63 8.98299 401.63 19.583C401.63 30.1831 410.345 38.7711 421.102 38.7711C431.86 38.7711 440.575 30.1831 440.575 19.583Z"
            fill={
              colors && colors[Math.floor(Math.random() * colors.length)]?.color
            }
          />
        </motion.g>

        <motion.g style={{ x: item2x, y: item2y }}>
          <motion.path
            d="M273.181 38.6453V115.172H350.843C350.843 72.9081 316.072 38.6453 273.181 38.6453Z"
            fill={
              colors && colors[Math.floor(Math.random() * colors.length)]?.color
            }
          />
          <motion.path
            d="M273.181 115.172H330.418C326.101 86.0809 302.698 63.0207 273.181 58.7712V115.172Z"
            fill={
              colors && colors[Math.floor(Math.random() * colors.length)]?.color
            }
          />
        </motion.g>

        <motion.g style={{ x: item3x, y: item3y }}>
          <motion.path
            d="M222.649 76.9113V38.6453H183.821V76.9113H222.649Z"
            fill={
              colors && colors[Math.floor(Math.random() * colors.length)]?.color
            }
          />
          <motion.path
            d="M222.649 115.172V76.9114H183.821V115.172H222.649Z"
            fill={
              colors && colors[Math.floor(Math.random() * colors.length)]?.color
            }
          />
          <motion.path
            d="M222.649 76.9113H261.483C261.483 55.7793 244.095 38.6453 222.649 38.6453V76.9113Z"
            fill={
              colors && colors[Math.floor(Math.random() * colors.length)]?.color
            }
          />
        </motion.g>

        <motion.g style={{ x: item4x, y: item4y }}>
          <motion.path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M169.821 95.1662C156.127 93.9281 145.391 82.4163 145.391 68.395H125C125 93.6828 144.851 114.33 169.821 115.605V95.1662Z"
            fill={
              colors && colors[Math.floor(Math.random() * colors.length)]?.color
            }
          />
          <motion.path
            d="M154 60.395L125 60.395L125 31.395C141.004 31.3915 154 44.3879 154 60.395Z"
            fill={
              colors && colors[Math.floor(Math.random() * colors.length)]?.color
            }
          />
        </motion.g>

        <motion.g style={{ x: item5x, y: item5y }}>
          <motion.path
            d="M77 68.395H117C117 90.4738 99.0739 108.395 77 108.395V68.395Z"
            fill={
              colors && colors[Math.floor(Math.random() * colors.length)]?.color
            }
          />
        </motion.g>

        <motion.g style={{ x: item6x, y: item6y }}>
          <motion.path
            d="M69 108.395H38V77.395C55.1222 77.395 69 91.2728 69 108.395Z"
            fill={
              colors && colors[Math.floor(Math.random() * colors.length)]?.color
            }
          />
          <motion.path
            d="M57.4699 108.395H38V88.925C48.7525 88.925 57.4699 97.6424 57.4699 108.395Z"
            fill={
              colors && colors[Math.floor(Math.random() * colors.length)]?.color
            }
          />
        </motion.g>

        <motion.g style={{ x: item7x, y: item7y }}>
          <motion.path
            d="M30 93.395C30 85.1107 23.2843 78.395 15 78.395C6.71573 78.395 0 85.1107 0 93.395C0 101.679 6.71573 108.395 15 108.395C23.2843 108.395 30 101.679 30 93.395Z"
            fill={
              colors && colors[Math.floor(Math.random() * colors.length)]?.color
            }
          />
          <motion.path
            d="M14.9998 100.092C11.302 100.092 8.30273 97.0929 8.30273 93.3951C8.30273 89.6973 11.302 86.698 14.9998 86.698C18.6976 86.698 21.6969 89.6973 21.6969 93.3951C21.6969 97.0929 18.6976 100.092 14.9998 100.092Z"
            fill={
              colors && colors[Math.floor(Math.random() * colors.length)]?.color
            }
          />
        </motion.g>
      </motion.svg>
    </div>
  )
}

export default BracketLeft
